import colors from "tailwindcss/colors";

const thematicGrass = {
  "400-a24": "rgba(76, 220, 90, 0.24)",
  "400-a48": "rgba(76, 220, 90, 0.48)",
};

const thematicGreen = {
  "100": "rgba(220, 252, 223, 1)",
  "200": "rgba(188, 246, 193, 1)",
  "400": "rgba(76, 220, 90, 1)",
  "500": "rgba(36, 195, 53, 1)",
  "700": "rgba(22, 117, 32, 1)",
};

export type ColorType =
  | "bg"
  | "stroke"
  | "accent-light"
  | "accent-strong"
  | "text"
  | "alpha-light"
  | "alpha-strong";
type RatingsColors = Record<
  "rating-optimal" | "rating-good" | "rating-fair" | "rating-degraded" | "rating-critical",
  Record<ColorType, string>
>;

const ratingColors: RatingsColors = {
  "rating-optimal": {
    bg: colors.emerald[200],
    stroke: colors.emerald[300],
    "accent-light": colors.emerald[500],
    "accent-strong": colors.emerald[600],
    text: colors.green[800],
    "alpha-light": `${colors.emerald[500]}40`, // alpha 25%
    "alpha-strong": `${colors.emerald[500]}80`, // alpha 50%
  },
  "rating-good": {
    bg: thematicGreen[100],
    stroke: thematicGreen[200],
    "accent-light": thematicGreen[400],
    "accent-strong": thematicGreen[500],
    text: thematicGreen[700],
    "alpha-light": thematicGrass["400-a24"],
    "alpha-strong": thematicGrass["400-a48"],
  },
  "rating-fair": {
    bg: colors.yellow[100],
    stroke: colors.yellow[200],
    "accent-light": colors.yellow[400],
    "accent-strong": colors.yellow[500],
    text: colors.yellow[700],
    "alpha-light": `${colors.yellow[400]}40`, // alpha 25%
    "alpha-strong": `${colors.yellow[400]}80`, // alpha 50%
  },
  "rating-degraded": {
    bg: colors.orange[100],
    stroke: colors.orange[200],
    "accent-light": colors.orange[400],
    "accent-strong": colors.orange[500],
    text: colors.orange[700],
    "alpha-light": `${colors.orange[400]}40`, // alpha 25%
    "alpha-strong": `${colors.orange[400]}80`, // alpha 50%
  },
  "rating-critical": {
    bg: colors.red[200],
    stroke: colors.red[300],
    "accent-light": colors.red[500],
    "accent-strong": colors.red[600],
    text: colors.red[800],
    "alpha-light": `${colors.red[500]}40`, // alpha 25%
    "alpha-strong": `${colors.red[500]}80`, // alpha 50%
  },
};

export const undefinedRatingColors = {
  bg: colors.gray[100],
  stroke: colors.gray[200],
  "accent-light": colors.gray[300],
  "accent-strong": colors.gray[500],
  text: colors.gray[700],
  "alpha-light": `${colors.gray[400]}40`, // alpha 25%
  "alpha-strong": `${colors.gray[400]}80`, // alpha 50%
};

export default ratingColors;
