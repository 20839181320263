const medalColors = {
  "medal-surface": {
    gold: "#F4DB63",
    silver: "#D2DDED",
    bronze: "#DAA168",
  },
  "medal-text": {
    gold: "#9E7A2A",
    silver: "#7B879E",
    bronze: "#90623C",
  },
  "medal-stroke": {
    gold: "#EEC649",
    silver: "#B9C6D8",
    bronze: "#BB7B41",
  },
};

export default medalColors;
