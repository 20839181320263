import colors from "tailwindcss/colors";
import ratingColors from "./src/constants/ratingColors";
import uiColors from "./src/constants/uiColors";
import medalColors from "./src/constants/medalColors";

const config = {
  content: ["./src/**/*.{js,ts,jsx,tsx,css}"],
  theme: {
    extend: {
      screens: {
        "3xl": "1800px",
      },
      height: {
        sm: "32rem",
        md: "40rem",
        lg: "48rem",
        xl: "64rem",
      },
      borderRadius: {
        none: "0",
        ws: "1px",
        sm: "2px",
        base: "4px",
        default: "4px",
        md: "6px",
        lg: "8px",
        xl: "12px",
        "2xl": "16px",
        full: "9999px",
      },
      transitionDuration: {
        10000: "10s",
      },
      colors: {
        green: colors.emerald,
        yellow: colors.amber,
        purple: colors.violet,
        ...ratingColors,
        ...uiColors,
        ...medalColors,
      },
      fontFamily: {
        "sans-serif": [
          '"IBM Plex Sans"',
          "sans-serif",
          "system-ui",
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
        ],
        serif: ["IBM Plex Serif", "serif"],
        mono: ['"IBM Plex Mono"', "monospace"],
        logo: ["Montserrat", "sans-serif"],
      },
      boxShadow: {
        xs: "0 1px 1px 0 rgba(0, 0, 0, .06), 0 1px 2px 0 rgba(0, 0, 0, .06)",
        sm: "0 1px 2px 0 rgba(0, 0, 0, .08), 0 2px 4px 0 rgba(0, 0, 0, .08)",
        base: "0 2px 4px 0 rgba(0, 0, 0, .12), 0 4px 8px 0 rgba(0, 0, 0, .08)",
        lg: "0 4px 8px 0 rgba(0, 0, 0, .12), 0 8px 16px 0 rgba(0, 0, 0, .12)",
        xl: "0 8px 12px 0 rgba(0, 0, 0, .08), 0 16px 32px 0 rgba(0, 0, 0, .24)",
        indigo: "0 4px 8px 0 rgba(46, 79, 209, .24)",
        red: "0 4px 8px 0 rgba(239, 68, 68, .24)",
        gauge:
          "0px 1px 1px 0px rgba(9, 21, 47, 0.05) inset, 0px 0px 0px 1px rgba(17, 24, 39, 0.02) inset",
        card: "0 0 12px rgba(0, 0, 0, .02), 0 0 32px rgba(0, 0, 0, .02), 0 0 0 1px rgba(17, 24, 39, 0.02)",
        "card-hover":
          "0 0 12px rgba(0, 0, 0, .05), 0 0 32px rgba(0, 0, 0, .05), 0 0 0 1px rgba(17, 24, 39, 0.1)",
      },
      typography: {
        DEFAULT: {
          css: {
            h1: {
              fontSize: "2.25rem",
              fontWeight: "500",
              lineHeight: "44px",
            },
          },
        },
      },
      backgroundImage: {
        "stripped-gray": "url('../../public/images/background/stripped.png')",
      },
      keyframes: {
        "width-load": {
          "0%": { "max-width": "0%" },
          "100%": { "max-width": "100%" },
        },
        "height-load": {
          "0%": { "max-height": "0%" },
          "100%": { "max-height": "100%" },
        },
      },
      animation: {
        "width-load": "width-load 1.0s cubic-bezier(0.5, 0, 0.5, 1)",
        "height-load": "height-load 1.0s cubic-bezier(0.5, 0, 0.5, 1)",
      },
    },
  },

  plugins: [
    require("@tailwindcss/container-queries"),
    require("@tailwindcss/forms"),
    require("@tailwindcss/typography"),
  ],
};

export default config;
