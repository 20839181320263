import cn from "classnames";
import type { MouseEvent, Ref } from "react";
import React, { forwardRef } from "react";

import LoadingDots from "~/components/ui/LoadingDots";

import styles from "./Button.module.css";

interface Props {
  block?: boolean;
  className?: string;
  download?: string;
  buttonClassName?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  autoFocus?: boolean;
  Component?: JSX.ElementType;
  href?: string; // for Component='a' buttons
  target?: string; // for Component='a' buttons
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
  loading?: boolean;
  loadingCentered?: boolean;
  shadow?: boolean;
  size?: "tiny" | "small" | "medium" | "large" | "xlarge";
  style?: React.CSSProperties | { "--tw-ring-color"?: string };
  type?: "primary" | "dark" | "light" | "white" | "outline" | "text" | "success";
  danger?: boolean;
  htmlType?: "button" | "submit" | "reset";
  ariaSelected?: boolean;
  ariaControls?: string;
  tabIndex?: 0 | -1;
  role?: string;
  title?: string;
}

const Button = (
  {
    block,
    className,
    children,
    download,
    danger,
    autoFocus = false,
    Component = "button",
    href,
    disabled = false,
    onClick,
    icon,
    iconRight,
    loading = false,
    shadow = true,
    size = "medium",
    style,
    type = "light",
    htmlType,
    ariaSelected,
    ariaControls,
    tabIndex,
    role,
    target,
    title,
  }: Props,
  ref: Ref<HTMLElement>
) => {
  return (
    <Component
      ref={ref}
      className={cn(
        styles["btn"],
        "focus-ring",
        {
          [styles[`btn-${type}`]]: type,
          [styles[`btn--${size}`]]: size,
          [styles["btn--w-full"]]: block,
          [styles["btn--w-full-icon-right"]]: !!iconRight,
          [styles["btn--danger"]]: danger,
          [styles["btn-container--shadow"]]: shadow,
          [styles["btn--text-fade-out"]]: loading,
          [styles["btn--disabled"]]: disabled,
          [styles["btn--square"]]: !children,
        },
        className
      )}
      disabled={loading || (disabled && true)}
      onClick={(event: MouseEvent<HTMLButtonElement>) => {
        if (!disabled && !loading && onClick) {
          onClick(event);
        }
      }}
      style={style}
      type={htmlType}
      aria-selected={ariaSelected}
      aria-controls={ariaControls}
      tabIndex={tabIndex}
      role={role}
      href={href}
      autoFocus={autoFocus}
      target={target}
      download={download}
      title={title}
    >
      {loading ? (
        <LoadingDots />
      ) : (
        <>
          {icon && <span className={styles["btn-icon"]}>{icon}</span>}
          {children && <span>{children}</span>}
          {iconRight && <span className={styles["btn-icon-right"]}>{iconRight}</span>}
        </>
      )}
    </Component>
  );
};

export default forwardRef(Button);
