import colors from "tailwindcss/colors";

const alphaWhite = {
  50: "rgba(235, 242, 255, 0.12)",
  100: "#F0F6FF2E",
  300: "rgba(6, 20, 50, 0.18)",
  500: "rgba(250, 252, 255, 0.67)",
  700: "rgba(250, 252, 255, 0.92)",
  900: "rgba(255, 255, 255, 0.98)",
};

const alphaGray = {
  300: "rgba(6, 20, 50, 0.18)",
  900: "rgba(17, 24, 39, 0.98)",
};

const uiColors = {
  "ui-surface": {
    high: colors.white,
    medium: colors.gray[50],
    low: colors.gray[100],
    well: colors.gray[200],
    inverse: colors.gray[900],
    active: colors.indigo[50],
    accent: colors.indigo[600],
  },
  "ui-surface-alpha-over-dark": {
    low: alphaWhite[50],
    medium: alphaWhite[100],
    inverse: alphaGray[900],
    active: colors.indigo[800],
    accent: colors.indigo[500],
    "alpha-light": alphaWhite[300],
  },
  "ui-text": {
    "high-contrast": colors.gray[900],
    "medium-contrast": colors.gray[700],
    "low-contrast": colors.gray[500],
    "very-low-contrast": colors.gray[400],
    inverse: colors.white,
    link: colors.indigo[600],
  },
  "ui-text-alpha-over-dark": {
    "low-contrast": alphaWhite[500],
    "medium-contrast": alphaWhite[700],
    "high-contrast": alphaWhite[900],
  },
  "ui-stroke": {
    light: colors.gray[100],
    base: colors.gray[200],
    strong: colors.gray[300],
    solid: colors.gray[900],
    inverse: colors.white,
    "base-alpha": "#041C4D1C", // alpha 11%
  },
};

export default uiColors;
